<template>
	<v-container fluid>
		<v-row justify="end">
			<v-col cols="4" class="pr-">
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search location"
					single-line
					outlined
					class="pr-7"
					dense
					hide-details
				></v-text-field>
			</v-col>
		</v-row>
		<div v-if="isLoading" color="grey lighten-4" class="pa-3">
			<v-row>
				<v-col cols="6" v-for="i in 2" :key="i">
						<v-skeleton-loader
							class="mx-auto"
							width="100%"
							type="card"
						></v-skeleton-loader>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row
				v-if="locations.length > 0"
				class="mx-0 pa-0 px-8 pt-4 d-flex flex-row"
			>
				<v-col
					cols="6"
					class="pa-4 d-flex"
					v-for="location in locations"
					:key="location.name"
				>
					<v-card
						class="d-flex justify-space-between flex-column"
						width="100%"
						height="100%"
					>
						<div>
							<v-card-title
								class="py-0 pt-4 mb-2 d-flex flex-row"
							>
								<a
									v-if="location.websiteUrl"
									class="name"
									:href="location.websiteUrl"
									target="_blank"
								>
									{{ location.locationName }}
								</a>
								<p v-else>
									{{ location.locationName }}
								</p>
							</v-card-title>
							<v-card-text>
								<p
									class="address"
									v-if="
										location.address &&
										location.address.addressLines.length > 0
									"
								>
									{{ location.address.addressLines[0] }},
									{{ location.address.addressLines[1] }},
									{{ location.address.locality }},
									{{ location.address.administrativeArea }},
									{{ location.address.postalCode }}
								</p>
								<p
									class="mt-1"
									v-if="
										location.address &&
										location.address.regionCode
									"
								>
									{{ location.address.regionCode }}
								</p>
							</v-card-text>
						</div>
						<div>
							<v-divider></v-divider>
							<v-card-text>
								<div
									class="d-flex flex-row justify-space-between mb-3"
								>
									<h1 class="card-section">
										Bussiness Type :
									</h1>
									<p
										v-if="
											location.primaryCategory &&
											location.primaryCategory.displayName
										"
									>
										{{
											location.primaryCategory.displayName
										}}
									</p>
									<p v-else>Not Available</p>
								</div>
								<div
									class="d-flex flex-row justify-space-between mb-3"
								>
									<h1 class="card-section">
										Bussiness Contact :
									</h1>
									<p v-if="location.primaryPhone">
										{{ location.primaryPhone }}
									</p>
									<p v-else>Not Available</p>
								</div>
								<div
									class="d-flex flex-row justify-space-between"
								>
									<h1 class="card-section">
										Bussiness Profile Status :
									</h1>
									<div class="d-flex flex-row">
										<span
											class="mdi mdi-check-decagram icon pr-2 verified"
											v-if="
												location.locationState
													?.isVerified
											"
										></span>
										<span
											class="mdi mdi-alert-decagram icon pr-2 unverfied"
											v-else
										></span>
										<p
											v-if="
												location.locationState
													?.isVerified
											"
										>
											Verified
										</p>
										<p v-else>Unverified</p>
									</div>
								</div>
							</v-card-text>
							<v-divider></v-divider>
							<v-card-actions
								class="px-4 d-flex flex-row justify-space-between"
							>
								<v-tooltip bottom v-if="location.websiteUrl">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="var(--blue)"
											outlined
											v-bind="attrs"
											v-on="on"
										>
											<a
												class="website"
												:href="location.websiteUrl"
												target="_blank"
											>
												Website
												<span
													class="mdi mdi-open-in-new"
												></span>
											</a>
										</v-btn>
									</template>
									<span class="tooltip-text"
										>Visit Website
									</span>
								</v-tooltip>
								<v-spacer></v-spacer>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="#5cbbf6"
											dark
											@click="selectlocation(location)"
											v-bind="attrs"
											v-on="on"
											:loading="selecting"
											>Select</v-btn
										>
									</template>
									<span class="tooltip-text">Manage GMB</span>
								</v-tooltip>
							</v-card-actions>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			search: null,
			selecting: false,
			locations: [],
			locationGroupLocations: [],
		}
	},
	watch: {
		search(val) {
			this.locations = this.locationGroupLocations.filter((location) => {
				return location.locationName.toLowerCase().includes(val)
			})
		},
	},
	async created() {
		this.getLocations()
	},
	methods: {
		getLocations() {
			this.isLoading = true
			const Organisation = this.$cookies.get('gmb-selected-org')
			const AccountEmail = this.$cookies.get('gmb-account-email')
			const ProjectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${ProjectId}/gmb/location`,
				data: {
					name: Organisation,
					email: AccountEmail,
				},
			})
				.then((response) => {
					if (
						response?.data?.data?.locations &&
						response?.data?.data?.locations?.length > 0
					) {
						this.$nextTick(function () {
							this.locations = response.data.data.locations
							this.locationGroupLocations = this.locations
						})
					} else {
						this.$store.commit(
							'gmb/selectedOrg',
							{ selectedOrg: Organisation },
							{
								root: true,
							}
						)
						this.$router.push({
							name: 'gmb.reviews',
						})
					}
					this.isLoading = false
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
					this.isLoading = false
				})
		},
		selectlocation(location) {
			this.$cookies.set('gmb-selected-location', location.name)
			this.$store.commit('gmb/selectedOrg', location)
			this.$router.push({ name: 'gmb.reviews' })
		},
	},
}
</script>

<style scoped>
.address,
.card-section {
	font-size: 1rem;
}
.icon {
	font-size: 20px;
}
.name:hover {
	color: var(--blue) !important;
}
.name {
	color: black !important;
	text-decoration: none !important;
}
.verified {
	color: #3f51b5;
}
.website {
	text-decoration: none !important;
	color: var(--blue) !important;
}
</style>
