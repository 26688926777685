var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pr-",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pr-7",attrs:{"append-icon":"mdi-magnify","label":"Search location","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.isLoading)?_c('div',{staticClass:"pa-3",attrs:{"color":"grey lighten-4"}},[_c('v-row',_vm._l((2),function(i){return _c('v-col',{key:i,attrs:{"cols":"6"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","type":"card"}})],1)}),1)],1):_c('div',[(_vm.locations.length > 0)?_c('v-row',{staticClass:"mx-0 pa-0 px-8 pt-4 d-flex flex-row"},_vm._l((_vm.locations),function(location){return _c('v-col',{key:location.name,staticClass:"pa-4 d-flex",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"d-flex justify-space-between flex-column",attrs:{"width":"100%","height":"100%"}},[_c('div',[_c('v-card-title',{staticClass:"py-0 pt-4 mb-2 d-flex flex-row"},[(location.websiteUrl)?_c('a',{staticClass:"name",attrs:{"href":location.websiteUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(location.locationName)+" ")]):_c('p',[_vm._v(" "+_vm._s(location.locationName)+" ")])]),_c('v-card-text',[(
									location.address &&
									location.address.addressLines.length > 0
								)?_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(location.address.addressLines[0])+", "+_vm._s(location.address.addressLines[1])+", "+_vm._s(location.address.locality)+", "+_vm._s(location.address.administrativeArea)+", "+_vm._s(location.address.postalCode)+" ")]):_vm._e(),(
									location.address &&
									location.address.regionCode
								)?_c('p',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(location.address.regionCode)+" ")]):_vm._e()])],1),_c('div',[_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"d-flex flex-row justify-space-between mb-3"},[_c('h1',{staticClass:"card-section"},[_vm._v(" Bussiness Type : ")]),(
										location.primaryCategory &&
										location.primaryCategory.displayName
									)?_c('p',[_vm._v(" "+_vm._s(location.primaryCategory.displayName)+" ")]):_c('p',[_vm._v("Not Available")])]),_c('div',{staticClass:"d-flex flex-row justify-space-between mb-3"},[_c('h1',{staticClass:"card-section"},[_vm._v(" Bussiness Contact : ")]),(location.primaryPhone)?_c('p',[_vm._v(" "+_vm._s(location.primaryPhone)+" ")]):_c('p',[_vm._v("Not Available")])]),_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('h1',{staticClass:"card-section"},[_vm._v(" Bussiness Profile Status : ")]),_c('div',{staticClass:"d-flex flex-row"},[(
											location.locationState
												?.isVerified
										)?_c('span',{staticClass:"mdi mdi-check-decagram icon pr-2 verified"}):_c('span',{staticClass:"mdi mdi-alert-decagram icon pr-2 unverfied"}),(
											location.locationState
												?.isVerified
										)?_c('p',[_vm._v(" Verified ")]):_c('p',[_vm._v("Unverified")])])])]),_c('v-divider'),_c('v-card-actions',{staticClass:"px-4 d-flex flex-row justify-space-between"},[(location.websiteUrl)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"var(--blue)","outlined":""}},'v-btn',attrs,false),on),[_c('a',{staticClass:"website",attrs:{"href":location.websiteUrl,"target":"_blank"}},[_vm._v(" Website "),_c('span',{staticClass:"mdi mdi-open-in-new"})])])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v("Visit Website ")])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#5cbbf6","dark":"","loading":_vm.selecting},on:{"click":function($event){return _vm.selectlocation(location)}}},'v-btn',attrs,false),on),[_vm._v("Select")])]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._v("Manage GMB")])])],1)],1)])],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }